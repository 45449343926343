<template>
  <el-upload
    class="upload-demo"
    :action="uploadVideo"
    :on-remove="handleRemove"
    :data="{ type: typeProps }"
    multiple
    :limit="maxCount"
    :on-exceed="handleExceed"
    :file-list="uploadfileList"
    :on-success="handleSuccess"
    v-model="uploadfileList"
    :before-upload="beforeAvatarUpload"
  >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">
      只能上传 mp4,avi,wmv,mpg,mpeg,mov,rm,ram,flv,swf 格式视频，且不超过50MB
    </div>
  </el-upload>
</template>
<script>
import { uploadVideo } from "@/api/common";
import { Message } from "element-ui";
import { getFileName } from "@/utils/utils";
import { UPLOAD_IMG_TYPE } from "@/utils/enumUtile";

export default {
  computed: {
    uploadVideo() {
      return uploadVideo;
    }
  },
  props: {
    typeProps: UPLOAD_IMG_TYPE,
    paramsType: String,
    model: Array,
    maxCount: {
      type: Number
    } // 最大个数
  },
  watch: {
    model(value) {
      this.setFileList(value);
    }
  },
  data() {
    return {
      uploadfileList: []
    };
  },
  created() {
    if (this.model) {
      this.setFileList(this.model);
    }
  },
  methods: {
    /**
     * 设置图片
     * @param img
     */
    setFileList(img) {
      this.uploadfileList = img;
    },
    /**
     * 删除
     * @param file
     * @param fileList
     */
    handleRemove(file, fileList) {
      console.log("fileList", fileList);
      const listArr = this.uploadfileList;

      const uid = file.uid;
      const resultListArr = listArr.filter(item => item.uid !== uid);

      this.uploadfileList = resultListArr;
      this.$emit("valueFunc", this.paramsType, resultListArr);
    },

    /**
     * 确认上传
     * @param res
     */
    handleSuccess(res) {
      console.log("res video",res)
      if (res.code !== 0) {
        Message({
          message: res.msg,
          type: "error",
          duration: 3 * 1000
        });
        return;
      }
      const listArr = this.uploadfileList;
      const obj = {
        name: getFileName(res.data.url),
        dbUrl: res.data.url,
        url: res.data.show_url
      };
      listArr.push(obj);
      console.log("listArr", listArr);
      this.uploadfileList = listArr;
      this.$emit("valueFunc", this.paramsType, listArr);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.maxCount} 个文件`);
    },

    beforeAvatarUpload(file) {
      const isJPG = [
        "video/mp4",
        "video/avi",
        "video/wmv",
        "video/mpg",
        "video/mpeg",
        "video/mov",
        "video/rm",
        "video/ram",
        "video/flv",
        "video/swf"
      ].includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 50;

      if (!isJPG) {
        this.$message.error(
          "上传视频只能是 mp4,avi,wmv,mpg,mpeg,mov,rm,ram,flv,swf 格式!"
        );
      }
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 50MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style scoped lang="scss"></style>
